<template>
  <div>
    <div class="mb-4">
      <div class="headline">Add media</div>
    </div>

    <connect-influencer-channel-container
      @continue="finished()"
    ></connect-influencer-channel-container>
  </div>
</template>

<script>
import ConnectInfluencerChannelContainer from "../containers/ConnectInfluencerChannelContainer";
export default {
  components: {
    ConnectInfluencerChannelContainer
  },
  methods: {
    finished() {
      this.$router.push({ name: "channels" });
    }
  }
};
</script>
