<template>
  <div>
    <div v-if="step === 1">
      <v-container fluid class="pa-0">
        <v-layout wrap>
          <v-flex v-for="channel in channels" :key="channel.uuid" lg4 md6 xs12>
            <div class="cursor-pointer" @click="channelClick(channel)">
              <v-card class="elevation-0 bordered m-1">
                <div class="p-1" :class="channel.name | channel_color">
                  <v-container fluid fill-height>
                    <v-layout align-center>
                      <v-icon color="#FFFFFF !important" :size="85">
                        {{ channel.icon }}
                      </v-icon>

                      <span class="display-1 white--text ml-5">{{ channel.display_name }}</span>
                    </v-layout>
                  </v-container>
                </div>
                <v-card-text>
                  <v-btn
                    v-if="channel.name === 'instagram'"
                    color="#4267B2"
                    class="white--text"
                    large
                    block
                    :disabled="loading"
                  >
                    <v-icon left color="#FFFFFF !important">fab fa-facebook</v-icon> Connect with Facebook
                  </v-btn>
                  <v-btn
                    v-else
                    color="#212121"
                    class="white--text"
                    large
                    block
                    :disabled="loading"
                  >
                     Connect
                  </v-btn>
                </v-card-text>
              </v-card>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div v-else-if="step === 2">
      <v-card>
        <v-toolbar color="error" dark flat dense cad>
          <v-toolbar-title class="subheading">Fejl!</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <p>
            We couldn't connect your Instagram profile to the platform.
          </p>

          <div class="font-weight-bold">
            How do I solve this?
          </div>

          <ul>
            <li>It has to be a public profile.</li>
            <li>It has to be a business profile.</li>
            <li>The profile has to be connected to a Facebook page.</li>
          </ul>
          <br />

          <p>
            You can read more here:
            <a
              href="https://makeinfluence.com/dk/influencer/blog/guides/saadan-opretter-du-en-virksomhedsprofil-paa-instagram"
              target="_blank"
              >How to create a business profile on Instagram</a
            ><br />
          </p>
          <p>
            If you're still experiencing issues, reach out to
            support@makeinfluence.com

          </p>

          <div class="text-center mt-3">
            <v-btn color="success" large @click="step = 1">
              Try again
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div v-else>
      <v-container fluid grid-list-lg class="pa-0">
        <v-layout wrap>
          <v-flex
            v-for="influencerChannel in filteredInfluencerChannels"
            :key="influencerChannel.id"
            lg4
            md6
            xs12
          >
            <connect-influencer-channel-card
              :influencer-channel="influencerChannel"
            ></connect-influencer-channel-card>
          </v-flex>
        </v-layout>
      </v-container>

      <div class="text-center mt-5">
        <v-btn color="success" large @click="$emit('continue', true)">
          Continue
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import channel_color from "@/helpers/filters/channel_color";
import ConnectInfluencerChannelCard from "../cards/ConnectInfluencerChannelCard";

export default {
  components: {
    ConnectInfluencerChannelCard
  },
  filters: { channel_color },
  props: {
    influencerChannels: {
      default: () => [],
      required: false
    }
  },
  data: () => ({
    step: 1,
    channels: [],
    loading: false,
    iInfluencerChannels: []
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    filteredInfluencerChannels() {
      // If prop is set use that instead
      if (this.influencerChannels.length > 0) {
        return this.influencerChannels;
      }

      // Display non active profiles OR active that hasn't been approved or where api failed
      return this.iInfluencerChannels.filter(
        item =>
          !item.active ||
          (item.active && (!item.approved_at || item.api_failed_at))
      );
    },
    windowLink() {
      return window.location.href;
    }
  },
  methods: {
    ...mapActions("influencer", ["getInfluencerChannels", "getChannels"]),

    channelClick(channel) {
      this.trackVirtualPageView("channel-connect/login");
      this.trackVirtualPageView("channel-connect/login/" + channel.name);
      this.loading = true;
      window.location.href =
        channel.login_link +
        "?user_uuid=" +
        this.user.uuid +
        "&redirectUrl=" +
        this.windowLink;
    }
  },
  watch: {
    step: {
      immediate: true,
      handler() {
        if (this.step === 2) {
          this.trackVirtualPageView("channel-connect/error");
        } else if (this.step === 3) {
          this.trackVirtualPageView("channel-connect/select-channels");
        }

        this.$emit("step", this.step);
      }
    }
  },
  created() {
    let params = this.$route.query;

    // Callback from server
    if (params.success !== undefined) {
      // If success show step 3
      if (params.success === "true") {
        this.getInfluencerChannels({
          influencer_uuid: this.user.influencer.uuid
        })
          .then(data => {
            this.iInfluencerChannels = data;
            if (data.length === 0) {
              this.step = 2;
            } else {
              this.step = 3;
            }
          })
          .catch(() => {
            this.loaded = true;
          });
      } else if (params.success === "false") {
        // If error show step 2 for error page..
        this.step = 2;
      }
    } else {
      this.trackVirtualPageView("channel-connect");
      this.getChannels().then(data => {
        this.channels = data;
      });
    }
  }
};
</script>

<style scoped></style>
